@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.sharedetails_main {
    display: flex;
    gap: 10px;
}

.sharedetails_list {
    background: #e4e4e4;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 3px;
    transform: scale(1);
    transition: all 0.3s ease;
    display: flex;
    cursor: pointer;
    max-height: 27px;

    &:hover {
        transform: scale(1.15);
        transition: all 0.3s ease;
    }
}

.sharedetails_click {
    display: flex;
}
