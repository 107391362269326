@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.card {
    max-width: 100%;
    position: relative;
    border: 0px solid #f9f9f9;
    border-radius: 8px;
    // height: 100%;

    @media only screen and (max-width: 767px) {
        height: 100%;
    }
    .card__cover {
        border-radius: 6px 6px 0px 0px;
        position: relative;

        .imageBox {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .imageContainer {
            width: 100%;
            background-color: #f9f9f9;
            border-radius: 6px;
        }

        .infomain {
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 18px;
            border: 1px solid #ebebeb;
            border-top: 0;
            border-radius: 0px 0px 6px 6px;
        }

        .cardtitle {
            background-color: #f9f9f9;
            width: 100%;
            // height: 41px;
            margin-bottom: 0;
        }

        .carddetails {
            background-color: #f9f9f9;
            width: 100%;
            // height: 54px;
            margin-bottom: 0;
        }
    }
}
